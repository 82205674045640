<script>
import { api } from '@/api'
import Cookies from 'js-cookie'
import { vueProps } from '@/vue-app'
import { APIS } from '@/store/mutation-types'

export default {
  methods: {
    isAdminRole () {
      const cachedApis = vueProps.$localStorage.get(APIS, {})
      return !!cachedApis.listHosts
    },
    copyObject (obj, target, namespace) {
      for (var idx in obj) {
        let kx = ''
        for (const cx in idx) {
          const c = idx[cx]
          if (c >= 'A' && c <= 'Z') {
            kx = kx + '.' + c.toLowerCase()
          } else {
            kx = kx + c
          }
        }
        if (namespace) {
          target[namespace + '.' + kx] = obj[idx]
        } else {
          target[kx] = obj[idx]
        }
      }
    },
    convertNamespaces (obj) {
      var params = {}
      for (var idx in obj) {
        var k = idx.split('.').join('_')
        var v = obj[idx]
        params[k] = v
      }
      return params
    },
    encodeVars (ansibleParams) {
      var paramString = JSON.stringify(ansibleParams)
      return Buffer.from(paramString).toString('base64')
    },
    copyToClipboard (text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'fixed'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    asyncApi (resource, params) {
      return new Promise((resolve, reject) => {
        api(resource, params || {}).then(json => {
          resolve(json)
        }).catch(function (error) {
          console.log(error.stack)
          reject(new Error())
        }).finally(() => {
        })
      })
    },
    waitForTask (jobId, loadingMessage, catchMessage, successMessage) {
      return new Promise((resolve, reject) => {
        this.$pollJob({
          jobId,
          loadingMessage,
          catchMessage,
          successMessage,
          successMethod: function (res) {
            resolve(res)
          },
          catchMethod: function () {
            reject(new Error('something bad happened'))
          }
        })
      })
    },
    waitForTaskSimple (jobId) {
      return new Promise((resolve, reject) => {
        this.$pollJob({
          jobId,
          successMethod: function (res) {
            resolve(res)
          },
          catchMethod: function () {
            reject(new Error('something bad happened'))
          }
        })
      })
    },
    async getBestServiceOfferings (ansibleParams) {
      var cache = {}
      var offerings = await this.asyncApi('listServiceOfferings', { listall: true, zoneid: ansibleParams.workspace_zone_id })
      for (var i in ansibleParams) {
        if (i.indexOf('service_offering') >= 0) {
          if (ansibleParams[i]) {
            if (!cache[ansibleParams[i]]) {
              cache[ansibleParams[i]] = await this.getBestServiceOffering(ansibleParams[i], offerings.listserviceofferingsresponse.serviceoffering)
            }
            ansibleParams[i] = cache[ansibleParams[i]]
          }
        }
      }
    },
    async getBestServiceOffering (serviceOfferingId, targetOfferings) {
      if (targetOfferings.filter((offering) => offering.id === serviceOfferingId).length) {
        return serviceOfferingId
      } else {
        var currentOffering = (await this.asyncApi('listServiceOfferings', { listall: true, id: serviceOfferingId })).listserviceofferingsresponse.serviceoffering[0]
        let bestOffering = null
        for (var i = 0; i < targetOfferings.length; i++) {
          var offering = targetOfferings[i]
          if (offering.memory >= currentOffering.memory && offering.cpunumber >= currentOffering.cpunumber) {
            if (!bestOffering || (bestOffering.memory > offering.memory && bestOffering.cpunumber > offering.cpunumber)) {
              bestOffering = offering
            }
          }
        }
        return bestOffering.id
      }
    },
    async getAnsibleBaseParams (workspace) {
      var userData = (await this.asyncApi('listUsers', { username: Cookies.get('username') })).listusersresponse.user[0]
      var userKeys = (await this.asyncApi('getUserKeys', { id: userData.id })).getuserkeysresponse.userkeys
      if (!userKeys.secretkey) {
        userKeys = (await this.asyncApi('registerUserKeys', { id: userData.id })).registeruserkeysresponse.userkeys
      }

      var tempAnsibleParams = {}
      tempAnsibleParams['cloudstack.url'] = location.protocol.concat('//').concat(window.location.host).concat(this.$config.apiBase)
      tempAnsibleParams['cloudstack.apikey'] = userKeys.apikey
      tempAnsibleParams['cloudstack.secretkey'] = userKeys.secretkey
      if (workspace && workspace.uuid) {
        let appliances = (await this.asyncApi('workspaceApplianceList', { workspace_uuid: workspace.uuid, type: 'ad-server' })).workspaceappliancelistresponse.payload.appliances
        var adserver = appliances[0]
        var adservernics = (await this.asyncApi('listVirtualMachines', { id: adserver.vmUuid, filter: 'nic' })).listvirtualmachinesresponse.virtualmachine[0].nic
        var adserverip = adservernics.filter(nic => nic.isdefault)[0].ipaddress
        appliances = (await this.asyncApi('workspaceApplianceList', { workspace_uuid: workspace.uuid, type: 'broker' })).workspaceappliancelistresponse.payload.appliances
        var broker = appliances[0]
        var brokernics = (await this.asyncApi('listVirtualMachines', { id: broker.vmUuid, filter: 'nic' })).listvirtualmachinesresponse.virtualmachine[0].nic
        var brokerip = brokernics.filter(nic => nic.isdefault)[0].ipaddress

        this.copyObject(workspace, tempAnsibleParams, 'workspace')
        tempAnsibleParams.workspace_dc_id = adserver.vmUuid
        tempAnsibleParams.workspace_dc_ipaddress = adserverip
        tempAnsibleParams.workspace_dc_username = adserver.username
        tempAnsibleParams.workspace_dc_password = adserver.password
        tempAnsibleParams.workspace_broker_id = broker.vmUuid
        tempAnsibleParams.workspace_broker_ipaddress = brokerip
        tempAnsibleParams.workspace_broker_username = broker.username
        tempAnsibleParams.workspace_broker_password = broker.password
      }
      return tempAnsibleParams
    }
  }
}
</script>
